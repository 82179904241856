// 路由配置
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'hash',  // 将模式设置为 hash
    routes: [
        {
            path: "/",
            name: "layout",
            redirect: "/home",
            component: () => import("../views/Layout.vue"),
            children: [
                {
                    path: "/home",
                    name: "home",
                    component: () => import("../views/Home.vue")
                },
                {
                    path: '/product',
                    name: 'product',
                    component: () => import("../views/Product.vue")
                },
                {
                    path: "/about",
                    name: "about",
                    component: () => import("../views/About.vue")
                },

            ]
        }
    ],
})

export default router;
